<template>
<div class="windows">
    <div class="windows-cover cover container-fluid px-0 mt-5">
        <div class="container">
            <div class="row mx-2">
                <div class="col-12 col-lg-6 cover-texts-bg my-5 md:ml-5">
                    <h4 class="text-white mx-5 mt-5 mb-4">Остъкление</h4>
                    <p class="text-white mx-5 my-4">
                        Освен стандартните почистващи услуги, имаме капацитета да поемем проекти за височинно почистване, свързани с използване на професионално оборудване - скелета, вишки и друга специализирана техника. Предлагаме както еднократна, така и абонаментна услуга за почистване на Вашите витрини, фасади, стъклени врати и повърхности. Благодарение на висококачествената технология, която използваме и обучените ни специалисти, гарантираме впечатляващи резултати.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5 px-2">
        <p>
            За проекти, включващи труднодостъпни пространства, работим с професионални алпинисти. Обучени и с дългогодишен опит, те могат да отстранят всякакви замърсявания без значение от височината, спазвайки всички съвременни изисквания за безопасност.
            <br /> <br>
            Работим по модерни методи, високи стандарти за качество на услугата и разполагаме с капацитет за почистване на замърсени фасадни повърхности с различна архитектура.<br />
            <br />
            В зависимост от степента на замърсяване, вида на фасадата и нейната облицовка, избираме най-подходящите препарати и прилагаме различни техники за почистване.<br />
            <br />
            Поради спецификата на всеки проект, при необходимост, наш специалист ще направи предварителен оглед. За нас е особено важно да разберем повече за характеристиките на обекта &ndash; квадратура, повърхност, височина, степен на замърсеност и други. В резултат, можем да Ви подадем коректна информация и да калкулираме най-добрата цена.<br />
            <br />
        </p>
    </div>
    <div class="container mt-5 px-5">
        <p class="py-2 message border-bottom border-top border-danger">Калкулатора важи за стандартни остъкления. За двустранно почистване, моля въведете квадратурата за всяка страна. При витрини над 3 метра височина (височинно почистване) и остъкления след ремонтни дейности цената варира, моля направете запитване през контактната ни форма или по телефона.</p>
    </div>
    <ServiceForm :servicePrice="4.5"/>
</div>
</template>

<script>
    import ServiceForm from '../../ServiceForm'

    export default {
        name: "Windows",
        components: {
            ServiceForm,
        }
    }
</script>